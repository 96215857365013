.generic-input-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 85px;
}

.generic-input-container label {
  font-size: var(--font-size-small);
  margin-bottom: 7px;
}
.generic-input-container select,
.generic-input-container textarea,
.generic-input-container input {
  border: 1px solid var(--secondary-color);
  color: #355b3e;
  border-radius: 5px;
  line-height: 32px;
  padding: 0.5rem;
  font-size: var(--font-size-small);
  outline: none;
  transition: all 0.2s ease-in-out;
}

.generic-input-container select {
  height: 50px;
}

.select-input-container {
  height: unset;
}
