.dashboard.paddocks.settings {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.horizontal-container.settings-inline-margins {
  margin-inline: 2%;
  width: 96%;
  height: fit-content;
  align-items: start;
}
