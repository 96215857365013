.sidebar-container {
  display: flex;
  top: 0px;
  left: 0px;
  flex-direction: column;
  background-color: #f5f5f5;
  min-height: 100%;
  padding: 24px 15px;
  position: fixed;
  width: 260px;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.15);
}

.sidebar-container h1 {
  font-size: 30px;
}

.sidebar-container img {
  width: 36px;
  height: 36px;
}

.sidebar {
  gap: 10px;
  height: fit-content;
  width: 100%;
  justify-content: center;
  margin-bottom: 38px;
}

.sidebar-container .links-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sidebar-container h5 {
  color: black;
}
