.add-category-container,
.add-paddock-container {
  min-height: 100vh;
}

.add-category-container h3,
.add-paddock-container h3 {
  text-align: center;
}

.add-paddock-container .main-card.wide-card.center .content,
.add-category-container .main-card.wide-card.center .content,
.add-category-container .main-card.wide.center .content {
  display: flex;
  flex-direction: column;
}

.add-paddock-container .vertical-container.form,
.add-category-container .vertical-container.form {
  flex: 1;
  margin-top: 10px;
  overflow-y: auto;
}

.add-paddock-container .vertical-container form,
.add-category-container .vertical-container form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.add-paddock-container .add-sub-categories,
.add-category-container .add-sub-categories {
  width: 100%;
  height: 50px;
  margin-bottom: 5px;
  min-height: 50px;
}

.add-paddock-container .form .input-container,
.add-category-container .form .input-container {
  margin-bottom: 5px;
}

.categories-card .title-container {
  display: flex;
  justify-content: space-between;
}

.sub-cat-hidden-bottom {
  position: absolute;
  bottom: -50px;
  transition: all 0.3s ease-in-out;
}

.categories-card .content {
  margin-bottom: 0px;
  height: -webkit-fill-available;
}
.categories-card:hover .sub-cat-hidden-bottom {
  bottom: 10px;
}

.sub-cat-hidden-bottom .button {
  padding-inline: 0px;
}

.add-category-container .main-card.center {
  max-height: unset;
  height: unset;
}
