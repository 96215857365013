.user-setting-container {
  margin-inline: 2%;
}

.user-setting-container .titles {
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  position: relative;
  flex-wrap: nowrap;
  height: fit-content;
}

.user-setting-container .horizontal-container {
  height: fit-content;
}

.user-setting-container .main-card.wide {
  width: 100%;
  max-width: unset;
  min-height: unset;
  height: fit-content;
}

.user-setting-container .main-card.wide .content {
  width: 95%;
  margin-inline: auto;
}
.user-settings-buttons-container {
  justify-content: flex-end;
}
