.contact-container .content {
  display: flex;
  flex-direction: column;
  height: fit-content;
  color: black;
  align-items: center;
  margin-inline: auto;
}
.contact-container .main-card {
  height: fit-content;
  width: 40%;
}

.contact-container .center-container {
  position: relative;
  height: fit-content;
}

.contact-container .vertical-container.form {
  width: 100%;
}

.contact-container .horizontal-container.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.contact-container .horizontal-container.button-container button {
  width: 100%;
}

.contact-container .generic-input-container {
  height: fit-content;
}

.contact-container .center-container {
  flex-direction: column;
}

.contact-container .success {
  margin-top: 20px;
  min-height: unset;
}

.contact-container .success p {
  color: var(--secondary-color);
  font-size: 24px;
}
