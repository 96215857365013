.about-us-container h3 {
  margin-top: 20px;
  width: 80%;
  margin-inline: auto;
  color: var(--white);
  text-align: center;
}
.about-us-container p {
  font-size: 24px;
}
.about-us-container .horizontal-container.half-spliter.first {
  margin-top: unset;
}

.about-us-container .horizontal-container.half-spliter.first .vertical-container {
  height: fit-content;
}

.about-us-container .half-spliter img {
  border-radius: 50px;
}

.about-us-container .half-spliter .paragraph {
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.about-us-container .half-spliter .vertical-container {
  padding-top: 0px;
  padding-bottom: 0px;
  height: fit-content;
}
