.checkbox-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox-input-container label {
  font-size: var(--font-size-small);
  margin-left: 7px;
}
.checkbox-input-container input {
  border: 1px solid var(--secondary-color);
  color: #355b3e;
  border-radius: 5px;
  line-height: 32px;
  padding: 0.5rem;
  font-size: var(--font-size-small);
  outline: none;
  transition: all 0.2s ease-in-out;
}
