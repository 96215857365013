.link-icon,
button.link-icon {
  display: flex;
  border-radius: 5px;
  padding-left: 15px;
  align-items: center;
  gap: 15px;
  font-size: var(--primary-font-size);
  height: 45px;
  color: var(--black);
}

.link-icon img {
  height: 30px;
  width: 30px;
}

.link-icon.active,
button.button.link-icon:hover {
  background-color: var(--primary-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  color: var(--white);
}

.active img {
  filter: invert(1);
}

button.button.link-icon {
  margin-top: 15px;
  background: none;
  border: none;
  box-shadow: none;
  width: 100%;
  font-family: var(--primary-font);
  padding-left: 10px;
}
