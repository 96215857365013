.vertical-container.list-with-children {
  width: 80%;
  margin-inline: auto;
  gap: 2px;
}

.list-with-children-container .horizontal-container {
  margin-bottom: 5px;
}

.list-with-children-container .horizontal-container {
  margin-bottom: 0px;
}

.vertical-container.list-with-children p {
  font-size: var(--small-font-size);
  line-height: var(--small-font-size);
}

.vertical-container.list-with-children .horizontal-container {
  height: calc(var(--small-font-size) + 5px);
}
