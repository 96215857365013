.blur-loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  backdrop-filter: blur(20px);
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.blur-loader-img-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5%;
  min-width: 30px;
  scale: 1;
}
.blur-animation-container {
  display: flex;
  flex-direction: column;
  animation: bounce 1.2s infinite;
}

@keyframes bounce {
  70% {
    transform: translateY(-20%);
  }
  80% {
    transform: translateY(0%);
  }
  90% {
    transform: translateY(-7%);
  }
  95% {
    transform: translateY(0%);
  }
  97% {
    transform: translateY(-3%);
  }
  99% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0);
  }
}
