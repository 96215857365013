.main-card {
  background: var(--white);
  border-radius: 20px;
  min-height: 200px;
  max-height: 600px;
  height: fit-content;
}

.card-list {
  margin-top: 20px;
  display: grid;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex;
  width: -webkit-fill-available;
  justify-content: left;
  row-gap: 20px;
}
.card-list .main-card {
  margin: 0 1%;
  min-width: 300px;
  max-width: 400px;
  width: 30%;
  height: unset;
}

.card-list-item {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  max-width: 33%;
}

.main-card .content {
  margin: 20px 35px;
  position: relative;
  width: 80%;
  height: 90%;
  overflow: hidden;
}

.main-card h3,
.main-card h4 {
  color: var(--black);
  font-size: var(--medium-big-font-size);
  font-family: var(--third-font);
  font-weight: 700;
  margin: 20px 0 12px 0;
}

.main-card h4 {
  font-size: var(--medium-font-size);
}

.main-card.wide-card,
.main-card.wide {
  min-width: 300px;
  max-width: 800px;
  width: 48%;
  margin-inline: 2%;
  height: 360px;
  margin: unset;
}

.card-list.farm-dashboard-list {
  margin-inline: 2%;
  gap: 2%;
  flex-wrap: wrap;
  row-gap: 20px;
}

.main-card.wide-card .content,
.main-card.wide .content {
  width: 85%;
}
.main-card.wide .stats,
.main-card.wide-card .stats {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.main-card.wide-card .stats:nth-child(1),
.main-card.wide .stats:nth-child(1) {
  border-right: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
}

.main-card.wide-card .stats:nth-child(2),
.main-card.wide .stats:nth-child(2) {
  border-bottom: 1px solid #cdcdcd;
}

.main-card.wide-card .stats:nth-child(3),
.main-card.wide .stats:nth-child(3) {
  border-right: 1px solid #cdcdcd;
}

.wide-card h2,
.wide h2 {
  text-align: center;
}

.card-add .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.card-add .icon-container {
  margin: unset;
}

.card-add .content h2,
.card-add .content p {
  color: var(--primary-color);
  text-align: center;
}
.horizontal-container.card-list-page {
  color: white;
  display: flex;
  justify-content: justify-content;
  align-items: justify-content;
  margin: 10px 20px;
  width: -webkit-fill-available;
}

.card-list-page button {
  width: 100px;
}

.main-card.ultra-wide {
  width: 100%;
  min-width: 600px;
  max-width: unset;
  height: fit-content;
  margin-inline: 0px;
}

.main-card.ultra-wide h2 {
  margin: 0;
  font-size: var(--bigest-font-size);
  color: var(--secondary-color);
  text-transform: capitalize;
}

.main-card.ultra-wide .content {
  width: 95%;
  margin: 15px auto;
}
.main-card.ultra-wide h2.error {
  color: var(--error-red);
}
