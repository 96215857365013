.column-settings {
  width: 49%;
}

.column-settings .title {
  height: fit-content;
  align-items: center;
}

.column-settings .content {
  height: fit-content;
  margin-inline: 4.5%;
  width: -webkit-fill-available;
}

.column-settings h2 {
  max-width: 50%;
}
