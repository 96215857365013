.button img {
  height: 100%;
  left: 0px;
}
.button.google {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  border: none;
  background-color: var(--white);
  color: var(--black);
  width: 100%;
  height: 50px;
  font-size: var(--medium-big-font-size);
}
