.create-movements .main-card.ultra-wide.options {
  min-height: unset;
  width: fit-content;
  padding-inline: 30px;
  min-width: unset;
  transition: width 0.2s ease-in-out;
}

.create-movements .main-card.ultra-wide.options .content {
  width: fit-content;
}
.create-movements .options .generic-input-container {
  height: 80px;
  justify-content: center;
}

.create-moevements-list {
  justify-content: center;
}

.create-movements .main-card .movements {
  width: fit-content;
}

.create-movements .options .horizontal-container {
  gap: 30px;
  flex-wrap: nowrap;
}

.create-movements .button-container {
  width: 55%;
  margin-inline: auto;
}

.create-movements table .generic-input-container {
  width: 90px;
  height: 51px;
  justify-content: center;
}

.create-movements table .generic-input-container input {
  line-height: 25px;
}
.movements-paddock-table .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.paddock-table-container {
  margin-top: 0px;
}

.paddock-table-container table {
  margin-top: 0px;
}

h4.observations-movements {
  color: var(--secondary-color);
}

.dashboard .ultra-wide .horizontal-container.transfer-obseravation {
  margin-bottom: 5px;
}

.create-movements table.is-editing .generic-input-container {
  width: 100%;
}

.create-moevements-list button.icon-button.paddock-icon-container {
  margin-bottom: 10px;
}
