.confirm-modal {
  position: absolute;
  box-shadow: 5px 5px 5px 5px rgb(0 0 0 / 25%);
  width: 500px;
  text-align: center;
  opacity: 0;
  animation: opacity-animation 0.5s ease-in-out forwards;
}

.center-container.modal {
  top: 0px;
  position: fixed;
  height: 100vh;
  width: 100vw;
  animation: blur-animation 0.3s ease-in-out forwards;
}

.center-container.modal.closing {
  animation: blur-animation-reverse 0.5s ease-in-out reverse;
}

.closing .confirm-modal {
  animation: opacity-animation-reverse 0.5s ease-in-out forwards;
}

.confirm-modal .content {
  margin-inline: auto;
}
@keyframes blur-animation {
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(10px);
  }
}

@keyframes opacity-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes blur-animation-reverse {
  from {
    backdrop-filter: blur(10px);
  }
  to {
    backdrop-filter: blur(0px);
  }
}

@keyframes opacity-animation-reverse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
