.card-with-image {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 70%;
  aspect-ratio: 1.678939617083947;
  min-width: 900px;
  max-width: 1600px;
  max-height: 700px;
  border-radius: 15px;
  background-color: var(--white);
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.25);
}

.card-with-image .content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2% auto;
  overflow: hidden;
  padding-inline: 5px;
}

.card-with-image img {
  margin-right: -1px;
}
