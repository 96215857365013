.farm-layout-header {
  width: 100%;
  gap: 1px;
  margin-left: 2%;
}

.vertical-container.farm-layout-header h2 {
  line-height: 35px;
  margin-left: 0%;
}
