.center {
  display: flex;
  justify-content: center;
}
.start {
  display: flex;
  justify-content: flex-start;
}
.end {
  display: flex;
  justify-content: flex-end;
}

.full-width {
  width: 100%;
}

.no-gap {
  gap: 0px;
}

.center {
  margin: auto;
}
