.paddocks-header .children-container {
  justify-content: unset;
  width: 80%;
}

.grid-add-paddock .vertical-container.input-container {
  display: grid;
  grid-template-columns: 1fr 70px;
}

.grid-add-paddock .number-container {
  width: 50px;
}

.paddocks table {
  margin-top: 15px;
}

.paddock-icon-container {
  display: flex;
  justify-content: center;
  height: 25px;
  margin-top: 5px;
}

.button.icon-button.paddock-icon-container {
  color: var(--primary-color);
  background: transparent;
}

.button.icon-button.paddock-icon-container:hover {
  color: white;
  background: var(--primary-color);
}

.paddocks-card-title {
  width: 40%;
}

.paddock-card-buttons {
  right: 0px;
  top: -60px;
  position: absolute;
  transition: 0.2s ease;
}

.ultra-wide:hover .paddock-card-buttons {
  top: 0px;
}

.paddock-observations {
  margin-top: 10px;
  height: fit-content;
  gap: 0px;
  margin-bottom: 0px;
}
.paddock-observations h3 {
  color: var(--secondary-color);
  margin-top: 0px;
  margin-bottom: 0px;
}
.paddock-observations ul {
  margin: 0px;
  margin-bottom: 10px;
}

.paddock-card.main-card {
  max-height: unset;
  overflow: hidden;
}
.paddock-observations table th {
  width: 90%;
  height: 0px;
}

.paddock-observations table tr td:first-child {
  width: unset;
}

.paddock-observations table tr td:nth-child(2) {
  width: 32px;
}

.paddock-observations table tr {
  border-top: unset;
}

.card-list.farm-dashboard-list.paddocks-list {
  row-gap: 0px;
  gap: 0px;
}

.paddocks-list .card-add {
  margin-bottom: 2%;
}
