.slogan-container {
  margin-bottom: 10px;
  display: unset;
}

.slogan-container h3 {
  text-align: left;
  font-weight: 600;
  margin-bottom: 5px;
}

.slogan-container p {
  text-align: left;
  color: var(--secondary-color);
}
