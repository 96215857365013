.input-icon-container {
  height: fit-content;
  position: relative;
}

.input-icon-container input {
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 5px;
  width: 240px;
  padding-left: 45px;
  color: var(--white);
}

.input-icon-container input::placeholder {
  color: var(--white);
}
.input-icon-container img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
}
