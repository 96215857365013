.logo-container {
  display: flex;
  gap: 20px;
  align-items: center;
  width: fit-content;
}
.logo-container img {
  width: 54px;
  height: 54px;
}

.logo-container span {
  color: var(--primary-color);
  font-family: var(--secondary-font);
}
