table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
  table-layout: auto;
}
tr {
  font-size: var(--primary-font-size);
  color: var(--secondary-color);
  height: 32px;
}

th {
  font-weight: 600;
}

td,
th {
  width: 1%;
}

tbody tr {
  border-top: 1px solid #cdcdcd;
}

.cell-container {
  width: fit-content;
  align-items: center;
}

.table-input {
  height: 32px;
}
.table-input input,
.generic-input-container select {
  width: unset;
  height: 32px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: unset;
}

table.is-editing {
  table-layout: fixed;
}

.isediting-col.button {
  border: unset;
  padding: unset;
  box-shadow: unset;
}
.isediting-col .button {
  background-color: unset;
  border: unset;
  padding: unset;
  box-shadow: unset;
  width: 100%;
  height: 100%;
}

.isediting-col {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
}

.isediting-col img {
  width: 25px;
  height: 25px;
}

.is-editing .isediting-header {
  width: 40px;
}

.is-editing th,
.is-editing td {
  width: unset;
}

.edit-col {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
}

.pending-movements .error-message {
  text-align: center;
}

.pending-movements h2.empty-message {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 10px;
}
