.horizontal-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  height: fit-content;
  padding: 0;
  margin: 0;
}
