.dashboard p,
.dashboard ul {
  font-family: var(--primary-font);
  font-weight: 400;
  font-size: var(--primary-font-size);
  line-height: 24px;
  color: var(--secondary-color);
}

.dashboard .horizontal-container {
  height: 24px;
  margin-bottom: 15px;
}
.dashboard .add-card {
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.dashboard .add-card h2 {
  width: fit-content;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.dashboard .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: auto;
}

.dashboard .add-card {
  cursor: pointer;
}

.farm-add-card .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  gap: 10px;
}

.farm-add-card .icon-container {
  margin: unset;
}
