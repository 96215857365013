.landing-container {
  width: 100%;
  height: 100vh;
  position: relative;
}
.landing-container:last-child {
  margin-bottom: 30px;
}
.landing-container .header-container {
  color: var(--white);
  margin: 30px auto;
  width: 95%;
  height: 40px;
  background-color: transparent;
}

.landing-container .header-container .primary {
  color: var(--white);
}

.landing-container .logo-container span {
  color: var(--white);
}

.landing-container .logo-container {
  height: 100%;
}

.landing-container .logo-container img {
  height: 100%;
  width: unset;
  aspect-ratio: 1;
}

.landing-container .logo-container h1 {
  height: 100%;
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
}

.landing-container .header-tabs {
  display: flex;
  width: fit-content;
  min-width: 400px;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}

.landing-container .header-tabs a {
  color: var(--white);
  position: relative;
  white-space: nowrap;
}

.landing-container .header-tabs a::after,
.landing-container .header-tabs a.active::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--white);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.landing-container .header-tabs a:hover::after,
.landing-container .header-tabs a.active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.landing-container .header-tabs .button {
  width: fit-content;
  padding-inline: 20px;
}

.landing-container .header-tabs .button:hover {
  background: transparent;
  color: var(--white);
  border-color: var(--white);
  width: fit-content;
  padding-inline: 20px;
}

.landing-container .landing-img {
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.landing-container .landing-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.landing-container .landing-content h1 {
  font-weight: 700;
  font-size: 120px;
  line-height: 173px;
  margin: 0;
  color: var(--white);
}

.landing-container .landing-content button {
  margin-inline: auto;
}

.landing-container .landing-content button:hover {
  background: transparent;
  color: var(--white);
  border-color: var(--white);
}

.half-spliter {
  position: relative;
}
.half-spliter > * {
  color: var(--white);
  width: 50%;
}

.landing-container .half-spliter.first {
  margin-top: calc(100vh - 100px);
  justify-content: center;
}

.landing-container .half-spliter {
  margin-top: 70px;
}

.landing-container .half-spliter h3 {
  color: var(--white);
}

.landing-container p {
  font-size: var(--primary-font-size);
  font-weight: 200;
}
.landing-container .landing-content img {
  width: 30px;
  height: 30px;
  margin-top: 30px;
  cursor: pointer;
}

.landing-container .half-spliter .vertical-container {
  justify-content: flex-start;
  padding: 40px 60px;
  height: 80%;
}

.half-spliter.center .vertical-container {
  justify-content: center;
}

.half-spliter .vertical-container {
  justify-content: center;
}

.half-spliter .vertical-container {
  max-width: 800px;
}
.half-spliter.center img {
  width: 100%;
  max-width: 800px;
}

.half-spliter.center h3 {
  font-size: var(--bigest-font-size);
}

.banner-container img {
  width: 100%;
}

.banner-container .vertical-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
}

.banner-container .vertical-container h1 {
  text-align: center;
  color: var(--white);
  font-size: 80px;
  font-weight: 800;
  white-space: nowrap;
}

.banner-container h3 {
  color: var(--white);
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.landing-cards .vertical-container {
  width: 20%;
  max-width: 400px;
  height: 100%;
}
.landing-cards {
  width: 90%;
  margin: auto auto;
}
.landing-cards .main-card .content {
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.landing-cards img {
  width: 50px;
  height: 50px;
  margin-bottom: 8px;
}

.landing-cards p {
  text-align: center;
}

.landing-cards .main-card {
  min-height: unset;
  margin-bottom: 0px;
  height: 100%;
}
