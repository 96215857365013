.login-container .content {
  width: 40%;
}
.optional {
  justify-content: center;
}

.forget-password {
  margin-bottom: 5px;
}
