@import url('https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css');
@import url('https://fonts.cdnfonts.com/css/inter');
@import url('https://fonts.googleapis.com/css?family=Poppins');
body {
  --primary-color: #2ab4ae;
  --secondary-color: #197a76;
  --disguised-color: #58745e;
  --black: #071e22;
  --white: #fff;
  --error-red: #ee2e31;
  --beije-general: #f4c095;
  --small-font-size: 12px;
  --primary-font-size: 16px;
  --medium-font-size: var(--primary-font-size);
  --medium-big-font-size: 20px;
  --big-font-size: 23px;
  --bigest-font-size: 32px;
  --max-font-size: 45px;
  --primary-font: 'Inter', sans-serif;
  --secondary-font: 'HK Grotesk', sans-serif;
  --third-font: 'Poppins', sans-serif;
  background-color: var(--secondary-color);
  font-family: var(--primary-font);
}

#root {
  min-width: 100%;
  min-height: 98vh;
  position: relative;
  height: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
  margin: 0;
  font-family: var(--primary-font);
}

h1 {
  font-weight: 700;
  font-size: var(--max-font-size);
  color: var(--black);
  font-family: var(--secondary-font);
}

h2 {
  font-family: var(--third-font);
  color: var(--secondary-color);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}

h3 {
  font-weight: 600;
  font-size: var(--big-font-size);
  color: var(--secondary-color);
  font-family: var(--primary-font);
}

h4 {
  font-weight: 600;
  font-size: var(--medium-big-font-size);
  color: var(--black);
  font-family: var(--secondary-font);
}

h5 {
  font-weight: 400;
  font-size: var(--medium-font-size);
  color: var(--disguised-color);
  font-family: var(--primary-font);
}
a {
  color: var(--secondary-color);
  text-decoration: none; /* no underline */
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

ul {
  list-style: disc;
  padding-left: 18px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.disabledFirstRealse {
  pointer-events: none;
  opacity: 0.5;
}

.hoverable {
  cursor: grab;
}

.banner-container {
  position: relative;
  width: 90%;
  object-fit: cover;
  margin: auto;
  border-radius: 50px;
  height: fit-content;
  margin-bottom: 50px;
}
.banner-contaier img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
