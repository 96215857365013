.error-message {
  color: var(--error-red);
  font-size: var(--small-font-size);
}
.error {
  color: var(--error-red);
}
.dashboard p.error-message {
  color: var(--error-red);
  font-size: var(--small-font-size);
}
