.said-settings {
  width: 49%;
}

.said-settings .title {
  height: fit-content;
}

.said-settings .content {
  height: fit-content;
  margin-inline: 4.5%;
  width: -webkit-fill-available;
}
