.movements-container {
  text-overflow: ellipsis;
}

.stats-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 75%;
}

.header-container h2 {
  color: var(--white);
  margin-left: 2%;
  width: fit-content;
}

.farm-add-card .content {
  margin-inline: auto;
  width: fit-content;
}
