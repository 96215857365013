.pending-movements-buttons .button {
  width: 32%;
}

.pending-movements-buttons {
  width: 60%;
}

.pending-movements-buttons .button:hover {
  width: 32%;
}

.create-movement-link {
  width: 30%;
}

.create-movement-link:hover {
  background: transparent;
  color: white;
  border-color: white;
}

.pending-movements .main-card.ultra-wide {
  max-height: unset;
  overflow: hidden;
}
