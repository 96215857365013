.button,
.button.secondary:hover {
  width: 47%;
  background-color: var(--primary-color);
  font-size: var(--primary-font-size);
  font-family: var(--secondary-font);
  color: var(--white);
  padding: 12px 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--primary-color);
  transition: 0.2s ease-in;
  overflow: hidden;
  text-align: center;
}

.button.secondary,
.button:hover {
  background-color: var(--white);
  color: var(--primary-color);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.button:disabled {
  background-color: var(--primary-color);
  color: var(--white);
  opacity: 0.5;
  cursor: not-allowed;
}

.button.red {
  background-color: transparent;
  color: var(--error-red);
  border: 1px solid var(--error-red);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.button.red.fill {
  background-color: var(--error-red);
  color: var(--white);
  border: 1px solid var(--error-red);
}
.button.red.fill:hover {
  background-color: transparent;
  color: var(--error-red);
}
.button.red:hover {
  background-color: var(--error-red);
  color: var(--white);
  border: 1px solid var(--error-red);
}

.farm-dashboard-list .ultra-wide .horizontal-container {
  height: fit-content;
}

.dashboard .horizontal-container .paddock-card-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.dashboard .ultra-wide .horizontal-container {
  margin-bottom: 0px;
}

.button.icon-button {
  width: 100%;
  height: 35px;
  padding: 1px;
  align-items: center;
}

.button.icon-button img {
  margin-right: 10px;
}
