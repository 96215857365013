.header-container {
  height: 60px;
  margin-left: 290px;
  width: -webkit-fill-available;
  padding-inline: 1%;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  height: fit-content;
}

.header-container .children-container {
  width: 60%;
  margin-left: 1%;
  gap: 2%;
}

.header-container h5,
.header-container p {
  color: var(--white);
  font-family: var(--primary-font);
}

.header-container h5 {
  font-size: var(--medium-font-size);
}

.header-container p {
  font-size: var(--small-font-size);
}
