.terms-container h2 {
  color: var(--white);
  margin-left: 3%;
}

.terms-container p {
  color: var(--white);
  margin-left: 3%;
  width: 94%;
  font-size: 24px;
}

.terms-container {
  color: var(--white);
}

.terms-container ol {
  margin: 10px auto;
  width: 85%;
}

.terms-container li {
  color: var(--white);
  margin-top: 15px;
}
